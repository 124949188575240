import { LoaderMessageService } from './utility-app/services/loader-message.service';
import { Component } from '@angular/core';
import {AppAuthenticationService} from './app-authentication.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'AirScanWeb';
  loadingMessage = 'Loading';
  shouldDisplayOverlay = true;
  constructor(private _authService: AppAuthenticationService, private _loaderMessageService: LoaderMessageService) {
     if (this._authService.isUserValid()) {
       this.shouldDisplayOverlay = false;
     }

     this._loaderMessageService.message.subscribe((message: string) => {
       this.loadingMessage = message || 'Loading';
      });
  }
}
