import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import {RouterModule} from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService} from './services/http-interceptor.service';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { NgxSpinnerModule, NgxSpinnerComponent } from 'ngx-spinner';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { InternalServerErrorComponent } from './components/internal-server-error/internal-server-error.component';
import { SnotifyModule, SnotifyService, ToastDefaults, SnotifyComponent } from 'ng-snotify';
import { ToggleRoleComponent } from './components/toggle-role/toggle-role.component';
import { PowerBiReportComponent } from './components/power-bi-report/power-bi-report.component';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComplaintComponent } from './components/complaint/complaint.component';
import { UploadModule } from '@progress/kendo-angular-upload';
import { TestimonialComponent } from '../utility-app/components/testimonial/testimonial.component';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    NgxSpinnerModule,
    SnotifyModule,
    NgxPowerBiModule,
    DropDownListModule,
    FormsModule,
    ReactiveFormsModule,
    UploadModule
  ],
  declarations: [HeaderComponent, FooterComponent, UnauthorizedComponent,
     PageNotFoundComponent, InternalServerErrorComponent, ToggleRoleComponent, PowerBiReportComponent, SidebarComponent, ComplaintComponent, TestimonialComponent],
  exports: [HeaderComponent, FooterComponent, UnauthorizedComponent, NgxSpinnerComponent, PageNotFoundComponent,
     InternalServerErrorComponent, SnotifyComponent, PowerBiReportComponent ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
  {provide: 'SnotifyToastConfig', useValue: ToastDefaults }, SnotifyService]
})
export class UtilityAppModule { }
