import { Injectable } from '@angular/core';
import { Router , CanLoad } from '@angular/router';
import { AppAuthenticationService } from './app-authentication.service';
import { AppRoutes } from './utility-app/app-constants/app-page-routes';

@Injectable({providedIn: 'root'})
export class AuthGuardService implements CanLoad {

  constructor(public auth: AppAuthenticationService, public router: Router) {}

  canLoad(): boolean {
    if (!this.auth.isUserValid()) {
      this.router.navigate([AppRoutes.HOME]);
      return false;
    }
    // else if (sessionStorage.getItem('sss') !== undefined) { // used to check authentication
    //      return false;
    // }
    return true;
  }

}
