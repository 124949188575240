import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppPropertiesService {
  applicationProperties = [];
  private sharedDataSource = new BehaviorSubject([]);
  properties = this.sharedDataSource.asObservable();

  constructor() { }

  changeNavigation(key: string, value: any) {
    this.applicationProperties[key] = value;
    this.sharedDataSource.next(this.applicationProperties);
  }
}
