import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { LoaderMessageService } from 'src/app/utility-app/services/loader-message.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RequestApi } from 'src/app/utility-app/app-constants/app-request-api';
import { APIResult } from 'src/app/utility-app/models/api-result.interface';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { map, tap } from 'rxjs/operators';
// import CustomerMasterModel from '../models/customer-master.model';
import { APPDATA } from 'src/app/utility-app/app-constants/app.data';
import { ComplaintModel } from '../models/complaint-model';
// import { SelectModel } from '../../models/common.model';

@Injectable({
  providedIn: 'root'
})
export class ComplaintMasterService {
  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [{ field: 'company', operator: 'contains', value: '' }]
    }
  };
  private data: any[] = [];

  constructor(private _http: HttpClient, private _loaderMessageService: LoaderMessageService) {  }


  // public getCountryList(): Array<string> {
  //   return APPDATA.COUNTRIES;
  //  }

  public createComplaint(complaintMaster:any,image:File): Observable<APIResult> {

    
    let formData=new FormData();
    if(image === undefined){
       formData.append('model',JSON.stringify(complaintMaster));
    }else{
        formData.append('model',JSON.stringify(complaintMaster));
        formData.append('image',image['rawFile']);
    }
    this._loaderMessageService.setLoaderMessage('Saving Support Request Details');
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-type', 'multipart/form-data');
    const options = {
      headers: httpHeaders
    };
   return  this._http.post<any>(RequestApi.MASTERS.COMPLAINT.ADD, formData, options);

  }

  // public getCustomerList(): Observable<any> {
  //   this._loaderMessageService.setLoaderMessage('Fetching customer records');
  //   const headers = new HttpHeaders().set('content-type', 'application/json');
  //   return this._http.post<any>(RequestApi.MASTERS.CUSTOMER.GET_CUSTOMERS, this.state, { headers });
  // }


}
