import { AuthorizationModel } from './utility-app/models/authorization-model';
import { Observable, of } from 'rxjs';
import { Component, OnInit, AfterContentChecked, AfterViewInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { AppAuthenticationService } from './app-authentication.service';
import { Router } from '@angular/router';
import { AppRoutes } from './utility-app/app-constants/app-page-routes';
import { UserRole } from './utility-app/app-constants/app-roles.enum';
import { RequestApi } from './utility-app/app-constants/app-request-api';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppPropertiesService } from './utility-app/services/nav-by-role.service';
import { AppProperties } from './utility-app/app-constants/app-properties.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-adal-authentication',
  template: `
  <div class="overlay" *ngIf="shouldDisplayOverlay" >
    <div>
      <h1>Checking for SSO session...</h1>
    </div>
  </div>
  `,
  styles: [`
  .overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    text-align: center;
    height: 100vh;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
  }
  `]
})
export class AdalAuthenticationComponent implements OnInit {
  private reidirectURl: string;
  shouldDisplayOverlay = true;
  constructor(private _navService: AppPropertiesService, private _http: HttpClient,
    private _authService: AppAuthenticationService, private _router: Router,
    private _spinnerService: NgxSpinnerService) {
  }

  ngOnInit() {
    
    window.localStorage.removeItem('signed');
    if (this._authService.isUserValid()) {
      
      
      this.shouldDisplayOverlay = false;
      this._router.navigate([AppRoutes.HOME]);

      // this.getRedirect().subscribe(result => {
      //   let that = this;
      //   that._router.navigate([ AppRoutes.HOME ]);
      //   // setTimeout(function () { that._router.navigate([ that.reidirectURl ]); }, 50);
      // });
      // this._navService.changeNavigation(AppProperties.USER_EMAIL, this._authService.getLoggedUserEmail());
      // this._navService.changeNavigation(AppProperties.USER_NAME, this._authService.getLoggedUserName());
      // const PARAMS = new HttpParams().set('email', this._authService.getLoggedUserEmail());
      // this._http.get<any>(RequestApi.AUTHORIZE_USER, { params: PARAMS }).subscribe(response => {
      //   const RESULT = response;
      //   if (RESULT.response.roleName === UserRole.ADMIN) {
      //     this._navService.changeNavigation(AppProperties.IS_ADMIN, true);
      //     this._navService.changeNavigation(AppProperties.IS_ENGINEER, false);
      //     this._navService.changeNavigation(AppProperties.IS_AUDITOR, false);
      //     this._router.navigate([AppRoutes.ADMIN.DASHBOARD]);
      //   } else if (RESULT.response.roleName === UserRole.SERVICE_ENGINEER) {
      //     this._router.navigate([ '/engineer/home' ]);

      //     // this._navService.changeNavigation(AppProperties.IS_ADMIN, false);
      //     // this._navService.changeNavigation(AppProperties.IS_ENGINEER, true);
      //     // this._navService.changeNavigation(AppProperties.IS_AUDITOR, false);
      //     // let self = this;

      //     //  setTimeout(function() {
      //     //     self._router.navigate([ '/engineer/home' ]);
      //     //   }, 50);
      //   } else if (RESULT.response.roleName === UserRole.AUDITOR) {
      //     this._navService.changeNavigation(AppProperties.IS_ADMIN, false);
      //     this._navService.changeNavigation(AppProperties.IS_ENGINEER, false);
      //     this._navService.changeNavigation(AppProperties.IS_AUDITOR, true);
      //     this._router.navigate([AppRoutes.ENGINEER.DASHBOARD]);
      //   } else {
      //     this.shouldDisplayOverlay = true;
      //     this._router.navigate([AppRoutes.HOME]);
      //   }
      // });
    } else {
      this.shouldDisplayOverlay = true;
      this._router.navigate([AppRoutes.HOME]);
    }
  }


  private getRedirect() {
    //  const PARAMS = new HttpParams().set('email', this._authService.getLoggedUserEmail());
    //  const httpHeaders = new HttpHeaders();
    //  httpHeaders.append('Content-type', 'application/json');
    //  return this._http.get<AuthorizationModel>(RequestApi.AUTHORIZE_USER, {headers: httpHeaders, params: PARAMS })
    //    .pipe(map(e => {
    //      if (e && e.roleName === UserRole.SERVICE_ENGINEER) {

    //       this.reidirectURl = AppRoutes.ENGINEER.DASHBOARD;
    //        return true;
    //      } else if (e && e.roleName === UserRole.AUDITOR ) {
    //        this.reidirectURl = AppRoutes.AUDITOR.DASHBOARD;
    //       } else if (e && e.roleName === UserRole.ADMIN ) {
    //         this.reidirectURl = AppRoutes.ADMIN.DASHBOARD;
    //        } else {
    //        if (this._router.url !== AppRoutes.UNAUTHORIZED_ACCESS) {
    //          this._router.navigate([AppRoutes.UNAUTHORIZED_ACCESS]);
    //        }
    //        return false;
    //      }
    //    }),
    //      catchError((err: any) => of(false)));
  }
}
