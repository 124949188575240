import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { IAppPowerBIConfig } from './utility-app/models/app-powerbi-config.interface';
import SampleJson from '../assets/config.powerbi.json';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AppPowerBiConfigService implements OnInit {
  static settings: IAppPowerBIConfig;
  static temp: any;

  constructor(private _http: HttpClient) {

  }

  public load() {
    return new Promise<void>((resolve, reject) => {
      const adalConfig: IAppPowerBIConfig = {
        // resource: SampleJson.powerBiAPIResource, // environment.resource,
        // tenant: environment.tenant, // 'atlascopco.com',
        // clientId: SampleJson.applicationId,
        // redirectUri: SampleJson.redirectUrl, // Environment.redirectUri,
        // endpoints: environment.endpoints,
        // navigateToLoginRequestUrl: environment.navigateToLoginRequestUrl,
        // cacheLocation: 'sessionStorage', //environment.cacheLocation,
        // extraQueryParameter: environment.extraQueryParameter, // 'domain_hint=atlascopco.com',
        // // logOutUri: window.location.origin,
        // isAngular: environment.isAngular,
        // postLogoutRedirectUri: environment.postLogoutRedirectUri


        resource: environment.resource, // environment.resource,
        tenant: environment.tenant, // 'atlascopco.com',
        clientId: environment.clientId,
        redirectUri: environment.redirectUri, // Environment.redirectUri,
        endpoints: environment.endpoints,
        navigateToLoginRequestUrl: environment.navigateToLoginRequestUrl,
        cacheLocation: 'sessionStorage', //environment.cacheLocation,
        extraQueryParameter: environment.extraQueryParameter, // 'domain_hint=atlascopco.com',
        // logOutUri: window.location.origin,
        isAngular: environment.isAngular,
        postLogoutRedirectUri: environment.redirectUri

      };
      AppPowerBiConfigService.settings = adalConfig;
      resolve();
    });
  }

  ngOnInit() {

  }
}
