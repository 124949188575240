// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  resource: 'https://analysis.windows.net/powerbi/api',
  tenant: 'atlascopco.com', // '556e6b1f-b49d-4278-8baf-db06eeefc8e9',
  clientId: 'efb26513-437f-4d57-b4ef-99506577fde7',
  // redirectUri: 'http://localhost:4200/',  //localhost URL
  //  redirectUri: 'https://airscantest.azurewebsites.net/',//test server URL
   redirectUri: 'https://airscan.atlascopco.com/', //production server URL
  endpoints: 
  {
    'https://analysis.windows.net': 'https://analysis.windows.net/powerbi/api'
  },
  navigateToLoginRequestUrl: false,
  cacheLocation: 'sessionStorage',
  extraQueryParameter: 'domain_hint=atlascopco.com',
  isAngular: true,

  //added from config.powerbi.json
  applicationSecret: "8kxLiR+Vl5Ewjq66OZS5bpC6ZuIFiyjbEw555i3l39E=",
  AADAuthorityUri: "https://login.windows.net/common/",
  powerBiDataset: "https://api.powerbi.com/v1.0/myorg/",
  powerBiApiUrl: "https://api.powerbi.com/",
  workspaceId: "c5a1e30e-9510-4916-964c-8dd99f585023",
  reportId: "e880757e-a055-49e4-8964-23207c54b661"
  //added from config.powerbi.json ends here

};

export const BaseVariable = Object.freeze({
  REPORT_URL: 'https://app.powerbi.com/reportEmbed?reportId=c19f96ed-9799-4ad5-b887-58b6784994d5&autoAuth=true&ctid=556e6b1f-b49d-4278-8baf-db06eeefc8e9&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1jLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D'
});

export const API = Object.freeze({
  // BASE_URL: `http://localhost:4994/api`,   //localhost api URL
     BASE_URL: 'https://airscanapi.azurewebsites.net/api'  // 'https://airscanwebapi.azurewebsites.net/api' //production api URL
    //  BASE_URL: 'https://airscanapitest.azurewebsites.net/api'  // 'https://airscanwebapi.azurewebsites.net/api' //test api URL
});

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
