import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DocumentsService } from '../../../superAdmin-app/documents/services/documents.service';
import { FileSaverService } from 'src/app/utility-app/services/file-saver.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {
  documentList: any[];

  constructor(
    private _documentsService: DocumentsService,
    private _fileSaverService: FileSaverService
  ) { }

  ngAfterViewInit() {
    $(document).ready(function () {
      $('[data-toggle=offcanvas]').click(function () {
        $('.row-offcanvas').toggleClass('active');
      });
    });
  }

  ngOnInit() {
    this._documentsService.getAllDocument().subscribe((data: any) => {
      this.documentList = data.response.records;
    });
  }

  public downloadDocument(documentName: string) {
    this._documentsService.downloadDocument(documentName).subscribe((result: any) => {
      this._fileSaverService.fileSave(result);
    });
  }

}
