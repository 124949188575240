import { Injectable } from "@angular/core";
import {
  SnotifyService,
  SnotifyPosition,
  SnotifyToastConfig,
  SnotifyToast,
} from "ng-snotify";
import { Observable, merge, of } from "rxjs";
import { mapTo } from "rxjs/operators";
import { NotificationType } from "../app-constants/app-notifications.enum";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  confirmFlag: Observable<boolean>;

  style = "material";
  title: string;
  body = "Welcome User";
  timeout = 5000;
  position: SnotifyPosition = SnotifyPosition.rightTop;
  progressBar = false;
  closeClick = true;
  newTop = true;
  filterDuplicates = false;
  backdrop = -1;
  dockMax = 8;
  blockMax = 1;
  pauseHover = true;
  titleMaxLength = 15;
  bodyMaxLength = 80;
  private notificationConfig: SnotifyToastConfig;

  constructor(private snotifyService: SnotifyService) {
    this.title = "AIRScan";
  }

  /*
    Change global configuration
     */

  get n() {
    return this.getConfig();
  }

  getConfig(): SnotifyToastConfig {
    this.snotifyService.setDefaults({
      global: {
        newOnTop: this.newTop,
        maxAtPosition: this.blockMax,
        maxOnScreen: this.dockMax,
      },
    });
    return {
      bodyMaxLength: this.bodyMaxLength,
      titleMaxLength: this.titleMaxLength,
      backdrop: this.backdrop,
      position: this.position,
      timeout: this.timeout,
      showProgressBar: this.progressBar,
      closeOnClick: this.closeClick,
      pauseOnHover: this.pauseHover,
    };
  }

  private constructNotification(...params: any[]): void {
    this.body = params[0]; // body
    this.title = params[1] || "AIRScan"; // title
    this.timeout = params[4] !== undefined ? params[4] : 5000;
    if (params[3]) {
      this.backdrop = 0.5;
    }
    this.notificationConfig = this.n;

    if (params[4] !== undefined) {
      // timeout
      this.timeout = params[4];
      this.notificationConfig = this.n;
    }
    if (params[2]) {
      // show sticky
      this.notificationConfig = this.n;
      this.notificationConfig.timeout = -1;
    }
  }

  public showNotification(
    type: NotificationType,
    body: string,
    title?: string,
    showSticky?: boolean,
    showBackDrop?: boolean,
    timeout?: number
  ): void {
    this.constructNotification(body, title, showSticky, showBackDrop, timeout);
    this.snotifyService[type](this.body, this.title, this.notificationConfig);
  }

  public clear(): void {
    this.snotifyService.clear();
  }

  public confirm(body: string, title: string): SnotifyToast {
    const { timeout, ...config } = this.getConfig(); // Omit props which don't need
    config.position = SnotifyPosition.centerTop;
    config.backdrop = 0.2;
    config.closeOnClick = true;
    this.constructNotification(body, title);
    return this.snotifyService.confirm(this.body, this.title, {
      ...config,
      buttons: [
        { text: "Yes", action: () => this.confirmed(true), bold: false },
        { text: "No", action: () => this.confirmed(false) },
      ],
    });
  }

  public MessageBox() {
    const message =
      "Please Use Optimization Platform https://optimizationplatform.atlascopco.com/ application to send the recommendations";
    console.log(message);

    this.snotifyService.confirm(message, {
      showProgressBar: false,
      timeout: 0,
      backdrop: -1,
      position: SnotifyPosition.centerCenter,
      buttons: [
        {
          text: "Close",
          action: (toast) => {
            this.snotifyService.remove(toast.id);
          },
        },
      ],
    });
  }

  public getConfirmation(): Observable<boolean> {
    if (this.confirmFlag === undefined) {
      this.confirmed(false);
    }
    return this.confirmFlag;
  }

  confirmed(flag: boolean): void {
    this.confirmFlag = merge(of(flag).pipe(mapTo(flag)));
  }
}
