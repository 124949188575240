/* [START] [CR000025] [20-April-2020] [Dipak Ahirav]

 */
import { Injectable, DebugElement } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { State } from '@progress/kendo-data-query';

import { Observable } from 'rxjs';

import { RequestApi } from 'src/app/utility-app/app-constants/app-request-api';
import { APIResult } from 'src/app/utility-app/models/api-result.interface';
import { LoaderMessageService } from 'src/app/utility-app/services/loader-message.service';

import DocumentsModel from '../models/documents.model';

@Injectable({
  providedIn: 'root'
})

export class TestimonialsService {
  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [{ field: 'documentName', operator: 'contains', value: '' }]
    }
  };
  public state2: State = {
    skip: 0,
    take: 2,
    filter: {
      logic: 'and',
      filters: [{ field: 'enabled', operator: 'isEqualTo', value: true }]
    }
  };
  private data: any[] = [];

  constructor(private _http: HttpClient, private _loaderMessageService: LoaderMessageService) { }

  public getDocumentForUpdate(documentId: number): Observable<DocumentsModel> {
    this._loaderMessageService.setLoaderMessage('Fetching Testimonial details');
    const PARAMS = new HttpParams().set('documentId', documentId.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this._http.get<DocumentsModel>(RequestApi.MASTERS.TESTIMONIAL.GET_TESTIMONIAL, { params: PARAMS, headers });
  }

  public createTestimonial(testimonialMaster:any,image:File,submitButtonName:string,updatedImage:string): Observable<APIResult> {
    
    let formData=new FormData();
    if(submitButtonName === "Create" || updatedImage === "updateNew"){

        formData.append('model',JSON.stringify(testimonialMaster));
        formData.append('image',image['rawFile']);
    }else{
        image['rawFile']=image;
        formData.append('model',JSON.stringify(testimonialMaster));
        formData.append('image',image['rawFile']);
    }
   
    this._loaderMessageService.setLoaderMessage('Uploading document details');

    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-type', 'multipart/form-data');
    const options = {
      headers: httpHeaders
    };
    
    return this._http.post<any>(RequestApi.MASTERS.TESTIMONIAL.CREATE_TESTIMONIAL, formData,options );
  }


  public getTestimonialList(): Observable<any> {
    this._loaderMessageService.setLoaderMessage('Fetching document records');
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this._http.post<any>(RequestApi.MASTERS.TESTIMONIAL.ALL_TESTIMONIAL, this.state, { headers });
  }

  public getAllTestimonial(): Observable<any> {
    this._loaderMessageService.setLoaderMessage('Fetching Testimonials records');
    const headers = new HttpHeaders().set('content-type', 'application/json');
    // const state: State = {
    //   skip: 0,
    //   take: 2147483647,
    //   filter: {
    //     logic: 'and',
    //     filters: [{ field: 'enabled', operator: 'isEqualTo', value: true }]
    //   }
    // };
    return this._http.post<any>(RequestApi.MASTERS.TESTIMONIAL.ALL_TESTIMONIAL, this.state2, { headers });
  }

  public updateTestimonialStatus(documentName: string): Observable<APIResult> {
    this._loaderMessageService.setLoaderMessage('Updating document status');
    const HEADERS = new HttpHeaders();
    HEADERS.append('Content-Type', 'application/json');
    const PARAMS = new HttpParams().set('documentName', documentName);
    return this._http.get<any>(RequestApi.MASTERS.DOCUMENTS.UPDATE_DOCUMENT_STATUS, { params: PARAMS, headers: HEADERS });
  }

  // public deleteDocument(documentName: string): Observable<APIResult> {
  //   this._loaderMessageService.setLoaderMessage('Deleting document details');
  //   const PARAMS = new HttpParams().set('documentName', documentName);
  //   return this._http.delete<APIResult>(RequestApi.MASTERS.DOCUMENTS.DELETE_DOCUMENT, { params: PARAMS });
  // }

  // public downloadDocument(documentName: string): Observable<any> {
  //   const HEADERS = new HttpHeaders();
  //   HEADERS.append('Content-Type', 'application/json');
  //   const PARAMS = new HttpParams().set('documentName', documentName);
  //   return this._http.get<any>(RequestApi.MASTERS.DOCUMENTS.DOWNLOAD_DOCUMENT, {
  //     params: PARAMS, headers: HEADERS,
  //     responseType: 'blob' as 'json', observe: 'response'
  //   });
  // }

  public getTestimonials(): Observable<any> {
    this._loaderMessageService.setLoaderMessage('Fetching testimonial records');
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const state: State = {
      skip: 0,
      take: 2147483647
    };
    return this._http.post<any>(RequestApi.MASTERS.TESTIMONIAL.ALL_TESTIMONIAL,state, { headers });
  }
}
/* [END] [CR000022] [05-Aug-2019] [Kartik] */
