import { SelectModel } from '../../../superAdmin-app/models/common.model';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AppRegExp } from 'src/app/utility-app/app-constants/app-regexp.enum';
import CustomerMasterModel from '../../../superAdmin-app/customer-master/models/customer-master.model';
import { NotificationService } from 'src/app/utility-app/services/notification.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CustomerMasterService } from '../../../superAdmin-app/customer-master/services/customer-master.service';
import { NotificationType } from 'src/app/utility-app/app-constants/app-notifications.enum';
import { APIResult } from 'src/app/utility-app/models/api-result.interface';
import { AppRoutes } from 'src/app/utility-app/app-constants/app-page-routes';
import * as introJs from 'intro.js/intro.js';
import { ComplaintMasterService } from '../../services/complaint-master.service';
import { AppAuthenticationService } from 'src/app/app-authentication.service';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthorizationModel } from '../../models/authorization-model';
import { RequestApi } from '../../app-constants/app-request-api';
import { CustomerRequiredDataService } from 'src/app/service-engineer-app/customer/services/customer-required-data.service';
import { UserMasterService } from 'src/app/superAdmin-app/user-master/services/user-master.service';
import { eventData } from '../../models/testimonial-model';


@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.scss']
})
export class ComplaintComponent implements OnInit {
  public complaintMasterForm: FormGroup;
  public industryTypeSelectList: Array<SelectModel>;
  public countrySelectList: Array<string>;
  public isComplaintMasterFormSubmitted = false;
  public RegExConstants = AppRegExp;
  public ActionCreate = true;
  public imageLength:number=0;
  private copiedCustomer: CustomerMasterModel;
  public imageFile:File;
  public userFamCode:any;
  public selectedFamecode=[];
  public userDetails:any;
  public rolesList:string;
  public supportImage:any;
  public displayImage:boolean=false;
  introJS = introJs();
  public userName:string;
  public firstName:string;
  public lastName:string;
  constructor(private _formBuilder: FormBuilder, private _complaintMasterService: ComplaintMasterService,
    private _notificationService: NotificationService, private _router: Router,
    private _route: ActivatedRoute, private _authService:AppAuthenticationService,
    private _appAuthService:AppAuthenticationService, private _mainService:CustomerRequiredDataService,
    private _http:HttpClient, private _userMasterService:UserMasterService) { 
       /*[START][CR000025][20-march-2020][DIPAK AHIRAV] - Application Tour*/
       this.introJS.setOptions({
        steps: [
        {
           element: '#step1',
           intro: "You can see Requestor’s Name.",
           position: 'bottom'
        },
        {
           element: '#step2',
           intro: "Enter Support request is regarding.",
           position: 'right'
        },
        {
           element: '#step3',
           intro: "You can see Requestor’s Email.",
           position: 'top'
        }
        ,
        {
           element: '#step4',
           intro: 'You can see Requestor’s Mobile No.',
           position: 'right'
        } 
        ,
        {
          element: '#step5',
          intro: 'You can see Requestor’s FAM Code.',
          position: 'right'
       },
        {
           element: '#step6',
           intro: 'Select Image / Screenshot(File type should be .jpg,.jpeg and .png).',
           position: 'right'
        }
     ],
     showProgress: true
    });
    /*[END][CR000025][20-march-2020][DIPAK AHIRAV] - Application Tour*/
    

    }

  // convenience getter for easy access to form fields
  get f() { return this.complaintMasterForm.controls; }



  ngOnInit() {

   
    // this.countrySelectList = this._customerMasterService.getCountryList();
    this.industryTypeSelectList =  [];
    this.complaintMasterForm = this.createComplaintMasterForm();
  
    this.userDetails=this._authService.getUserInfo();

    this.firstName=this.userDetails.profile.given_name+" ";
    this.lastName=this.userDetails.profile.family_name;
    this.userName=this.firstName.concat(this.lastName);
    this.complaintMasterForm.patchValue({
      'requestorName':this.userName
    })
  
    // this.userName=this._authService.getLoggedUserName();
 
/*[START][CR000025][Dipak Ahirav]*/

const PARAMS = new HttpParams().set('email', this._appAuthService.getLoggedUserEmail());
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-type', 'application/json');
    
    this._http.get<AuthorizationModel>(RequestApi.AUTHORIZE_USER, { headers: httpHeaders, params: PARAMS }).subscribe(result => {
          
          this.rolesList= Array.prototype.map.call(result.userRoles,function(item){
            return item.roleName;
          }).join(", ");
        
          this.complaintMasterForm.patchValue({
            'mobileNo':result.phoneNumber,
            'role':this.rolesList,
            'email':result.email
          })
          // this.userRoles = result.userRoles;
          this._mainService.getUserFamCodes(result.userId).subscribe((result: any) => {
           
              
             this.userFamCode= Array.prototype.map.call(result.response,function(item){
                return item;
              }).join(", ");
             
              this.complaintMasterForm.patchValue({
                'famCode':this.userFamCode
              })
      
              
          });
          
         
    });

   

/*[END][CR000025][Dipak Ahirav]*/
  }

  
  /*[START][CR000025][DIPAK AHIRAV][20-March-2020] - Application Tour*/
  public applicationTour(){
      
    this.introJS.start();
  
  }
/*[END][CR000025][DIPAK AHIRAV][20-March-2020] - Application Tour
this._authService.getLoggedUserName()
*/
  private createComplaintMasterForm(): FormGroup {
    return this._formBuilder.group({
      ComplaintId:new  FormControl(0),
      requestorName: new FormControl(null),

      supportRequestRegarding: new FormControl(null, [Validators.required, Validators.maxLength(150)]),
      mobileNo: new FormControl(null, Validators.compose([Validators.pattern(this.RegExConstants.NUMERIC)])),
      email: new FormControl(null, [Validators.required, Validators.pattern(this.RegExConstants.EMAIL.RegEx), Validators.maxLength(50)]),
      famCode:new FormControl(null,[Validators.required]),
      image:new FormControl(null),
      role:new FormControl(null)
  });

  }

  public onSubmitComplaintMasterForm(event): any {
    // if (!this.ActionCreate) {
    //   if (deepEqual(this.copiedCustomer, this.complaintMasterForm.value )) {
    //     this._notificationService.showNotification(NotificationType.INFO, 'Nothing changed to update',
    //    `${this.submitButtonName} Customer`);
    //     return;
    //   }
    // }
    
    this.isComplaintMasterFormSubmitted = true;

    if (this.complaintMasterForm.valid) {
     this._complaintMasterService.createComplaint(this.complaintMasterForm.value,this.imageFile).subscribe((result: APIResult) => {
      if (result && result.status) {
        this._notificationService.showNotification(NotificationType.SUCCESS, result.message);
        this._router.navigate([AppRoutes.TOGGLE_ROLE]);
      } else {
        this._notificationService.showNotification(NotificationType.ERROR, result.message);
      }
     });
    } else {
      this._notificationService.showNotification(NotificationType.ERROR, 'Please fill all mandatory fields before save');
    }
  }

  public onResetUserMasterForm(): void {
    if (this.ActionCreate) {
      this.complaintMasterForm.reset();
    } else {
      this.complaintMasterForm.patchValue(this.copiedCustomer);
    }
  }

  public onBackToCustomerList(): void {
    this._router.navigate([AppRoutes.SUPER_ADMIN.MASTERS.CUSTOMER.LIST]);

  }

  public imageFileSelectEventHandler(e: any): void {
    if (e && e.files.length > 0) {
      for (const file of e.files) {
        if(file.extension == '.jpeg' || file.extension == '.png' || file.extension == '.gif' || file.extension == '.jpg'||
        file.extension == '.JPEG' || file.extension == '.PNG' || file.extension == '.GIF' || file.extension == '.JPG'){

          this.imageLength+=1;
          // this.complaintMasterForm.patchValue({
          //   image:file.name
          // })
          this.imageFile=e.files[0];
          /*[START][CR000025][DIPAK AHIRAV][20-April-2020] upload image display */
          this.displayImage=true;
          var reader = new FileReader();
          reader.readAsDataURL(e.files[0]['rawFile']); 
          reader.onload = (_event:eventData) => { 
            this.supportImage = _event.target.result; 
          }
         /*[END][CR000025][DIPAK AHIRAV][20-April-2020] upload image display */
         
        } else {
          e.preventDefault();
          this._notificationService.showNotification(NotificationType.ERROR, 'File type not allowed', 'Document');
        }
      }
    }
  }

 
  public imageFileRemoveEventHandler(e: any): void {
    if (e && e.files.length > 0 ) {
      // this.document = null;
      this.imageLength-=1;
      // this.supportImage=null;
      this.displayImage=false;
      // this.complaintMasterForm.patchValue({
      //   image:null
      // })
    }
    else{

    }
  }
}
