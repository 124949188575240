import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { LoaderMessageService } from 'src/app/utility-app/services/loader-message.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RequestApi } from 'src/app/utility-app/app-constants/app-request-api';
import { APIResult } from 'src/app/utility-app/models/api-result.interface';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { map, tap } from 'rxjs/operators';
import CustomerMasterModel from '../models/customer-master.model';
import { APPDATA } from 'src/app/utility-app/app-constants/app.data';
import { SelectModel } from '../../models/common.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerMasterService {
  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [{ field: 'company', operator: 'contains', value: '' }]
    }
  };
  private data: any[] = [];

  constructor(private _http: HttpClient, private _loaderMessageService: LoaderMessageService) {  }


  public getCountryList(): Array<string> {
    return APPDATA.COUNTRIES;
   }


   public getIndustryTypeList(): Observable<Array<SelectModel>> {
    this._loaderMessageService.setLoaderMessage('Fetching required data');
    const HEADERS = new HttpHeaders().set('content-type', 'application/json');
    return this._http.get<Array<SelectModel>>(RequestApi.INDUSTRY_TYPE.GET_SELECT_LIST, {headers: HEADERS});
  }

  public getCustomerForUpdate(customerId: number): Observable<CustomerMasterModel> {
    this._loaderMessageService.setLoaderMessage('Fetching customer details');
    const PARAMS = new HttpParams().set('customerId', customerId.toString());
    const HEADERS = new HttpHeaders().set('content-type', 'application/json');
   return  this._http.get<CustomerMasterModel>(RequestApi.MASTERS.CUSTOMER.GET_CUSTOMER, { params: PARAMS, headers: HEADERS });
  }

  public createOrUpdateCustomer(customer: CustomerMasterModel): Observable<APIResult> {
    
    this._loaderMessageService.setLoaderMessage('Saving customer details');
    const HEADERS = new HttpHeaders().set('content-type', 'application/json');
    // const params = new HttpParams().set('SupplierName', supplier);
    //  params.set('Location', location);
   // return  this._http.get<any>(environment.api_url + 'supplier/SaveSequenceNumber/?SupplierName=' + supplier + '&Location=' + location);
   return  this._http.post<any>(RequestApi.MASTERS.CUSTOMER.UPSERT, customer, {headers: HEADERS});

  }

  public getCustomerList(): Observable<any> {
    this._loaderMessageService.setLoaderMessage('Fetching customer records');
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this._http.post<any>(RequestApi.MASTERS.CUSTOMER.GET_CUSTOMERS, this.state, { headers });
  }
  /*[START][CR000025][Dipak Ahirav][16-April-2020]- export excel All data*/
  public getAllCustomerList(): Observable<any> {
    this._loaderMessageService.setLoaderMessage('Fetching customer records');
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const state: State = {
      skip: 0,
      take:99999999
    };
    return this._http.post<any>(RequestApi.MASTERS.CUSTOMER.GET_CUSTOMERS, state, { headers });
  }
  /*[END][CR000025][Dipak Ahirav][16-April-2020]- export excel All data*/

}
