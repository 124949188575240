import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHeaders,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, pipe, of, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppAuthenticationService } from '../../app-authentication.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from './notification.service';
import { NotificationType } from '../app-constants/app-notifications.enum';
import { AppRoutes } from '../app-constants/app-page-routes';
import { LoaderMessageService } from './loader-message.service';
import jsonConfig from '../../../assets/config.powerbi.json';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  protected workspaceId = jsonConfig.workspaceId;
  protected powerBiDataset = jsonConfig.powerBiDataset;
  constructor(
    private _appAuthService: AppAuthenticationService,
    private _router: Router,
    private _spinnerService: NgxSpinnerService,
    private _notificationService: NotificationService,
    private _loaderMessageService: LoaderMessageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const headers = new HttpHeaders().set('content-type', 'application/json');

    if (!this._appAuthService.isUserValid()) {
       this._appAuthService.signOutUser();
       this._notificationService.showNotification(
        NotificationType.INFO,
        'Your session timed out. Please log in to continue',
        '',
        true,
        false
      );
       return throwError('session timed out');
    }

    let headers: HttpHeaders = new HttpHeaders()
    // .append('content-type', 'application/json')
      // .append('APIKey', 'ff2fc8cf211cfb0da417b3d74c2ef31f');
      // tslint:disable-next-line:max-line-length
    //  .append('Content-type', 'multipart/form-data')
     // .append('Accept', 'application/json')




      .append('Authorization', 'Bearer ' + this._appAuthService.getToken())
      .append('Cache-Control', 'no-cache')
      .append('pragma', 'no-cache')
      .append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
      // .append('IsValidUser', sessionStorage.getItem('IsValidUser'))
      .append('Email', this._appAuthService.getLoggedUserEmail());
     // 'https://api.powerbi.com/v1.0/myorg/groups/c5a1e30e-9510-4916-964c-8dd99f585023/dashboards'
      if (req.url === `${this.powerBiDataset}groups/${this.workspaceId}/dashboards`)
     {
       const newHeaders = new HttpHeaders()
       .append('Authorization', 'Bearer ' + this._appAuthService.getPowerBiToken());
       headers = newHeaders;
     }
    const request = req.clone({
      headers: headers
    });
    this._spinnerService.show();
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this._spinnerService.hide();
            this._loaderMessageService.setLoaderMessage('Loading');
          }
        },
        (err: HttpErrorResponse) => {
          this._spinnerService.hide();
          this._loaderMessageService.setLoaderMessage('Loading');
          if (err) {
            console.error(err.message);
            if (err.status && err.status === 401) {
              this._notificationService.showNotification(
                NotificationType.WARNING,
                'You are not authorized to view the content of this page',
                '',
                true,
                true
              );
              this._router.navigate([AppRoutes.UNAUTHORIZED_ACCESS]); // unauthorized acess
            } else if (err.status === 500) {
              this._notificationService.showNotification(
                NotificationType.ERROR,
                'Internal server error occured',
                'Error!',
                false,
                false
              );
              this._router.navigate([AppRoutes.INTERNAL_SERVER_ERROR]); // internal server error
            } else if (err.status === 404) {
              this._notificationService.showNotification(
                NotificationType.ERROR,
                err.error,
                '',
                false,
                false
              );
             // this._router.navigate([AppRoutes.PAGE_NOT_FOUND]); // internal server error
            } else if (err.status === 400) {
              this._notificationService.showNotification(
                NotificationType.ERROR,
                err.error,
                '',
                false,
                false
              );
              } else if (err.status === 503 || err.status === 0) {
              this._notificationService.showNotification(
                NotificationType.ERROR,
                err.statusText,
                '',
                true,
                false
              );
              // this._router.navigate([AppRoutes.HOME]); // internal server error
            } else if (err.status === 409) {
              this._notificationService.showNotification(
                NotificationType.ERROR,
                err.error['errors'],
                '',
                false,
                false
              );
              // this._router.navigate([AppRoutes.HOME]); // internal server error
            }
          }
        }
      )
    );
  }
}
