import { API } from "src/environments/environment";

export const RequestApi = Object.freeze({  

  POST_Name: `${API.BASE_URL}/`,
  
  // Region : Misc
  ATTACHMENT: {
    DELETE: `${API.BASE_URL}/Attachments/DeleteAttachment`,
    DOWNLOAD: `${API.BASE_URL}/Attachments/DownloadAttachment`,
    DOWNLOAD_AUDITED_REPORT: `${API.BASE_URL}/Attachments/DownloadAuditedReport`,
    DOWNLOAD_SAMPLE: `${API.BASE_URL}/Attachments/DownloadSample?filename=Measurement_Reports.xlsx`,
    DELETE_ATTACHMENT:`${API.BASE_URL}/Attachments/DeleteAttachmentFile`
  },
  // End

  // Region : Account
  AUTHORIZE_USER: `${API.BASE_URL}/Account/AuthorizeUser`,
  // End

  // Region: User Master
  MASTERS: {
    USER: {
      GET_ALL_USERS:`${API.BASE_URL}/UserMaster/GetAllUsers`,
      GET_SELECTED_FAM_CODE_USERS:`${API.BASE_URL}/UserMaster/GetSelectedFamCodeUsers`,
      GET_USERS: `${API.BASE_URL}/UserMaster/GetAll`,
      GET_USER_INFO_FROM_AD: `${API.BASE_URL}/UserMaster/GetUserInformationFromAD`,
      GET_ROLES: `${API.BASE_URL}/UserMaster/GetRolesSelectList`,
      UPSERT: `${API.BASE_URL}/UserMaster/Create`,
      GET_USER: `${API.BASE_URL}/UserMaster/Update`,
      GET_FAM_CODE_LIST: `${API.BASE_URL}/UserMaster/GetFAMCodeSelectList`,
      GET_TEAM_LEADER_LIST: `${API.BASE_URL}/UserMaster/GetTeamLeaderSelectList`,
      GET_KEY_FAM_CODE_LIST:`${API.BASE_URL}/UserMaster/GetFAMCodeSelectListByKeyValue`
    },
    CUSTOMER: {
      GET_CUSTOMERS: `${API.BASE_URL}/Customer/GetAll`,
      GET_FAMCODE_CUSTOMER:`${API.BASE_URL}/Customer/GetSelectedFamcodeCustomer`,
      UPSERT: `${API.BASE_URL}/Customer/Create`,
      GET_CUSTOMER: `${API.BASE_URL}/Customer/Update`
    },
    COMPLAINT:{
      ADD:`${API.BASE_URL}/UserMaster/CreateComplaint`,
      LIST:`${API.BASE_URL}/UserMaster/GetComplaint`,
      VIEW:`${API.BASE_URL}/UserMaster/GetComplaintById`
    },
    TESTIMONIAL:{
      CREATE_TESTIMONIAL:`${API.BASE_URL}/Document/CreateAll`,
      GET_TESTIMONIAL: `${API.BASE_URL}/Document/Get`,
      ALL_TESTIMONIAL:`${API.BASE_URL}/Document/GetAllTestimonial`
    },

    /* [START] [CR000022] [24-Jul-2019] [Kartik]
     * Tab - Recommendation
     */
    RECOMMENDATION: {
      GET_RECOMMENDATIONS: `${API.BASE_URL}/Recommendation/GetAll`,
      UPSERT: `${API.BASE_URL}/Recommendation/Create`,
      GET_RECOMMENDATION: `${API.BASE_URL}/Recommendation/Update`,
      /* [START] [CR000025] [03-Feb-2020] [Kartik]
       * Auditor
       * c.	As an admin I can see new criteria for already added recommendations
       */
      GET_RECOMMENDATION_CRITERIA: `${API.BASE_URL}/Recommendation/GetRecommendationCriteria`,
      /* [END] [CR000025] [03-Feb-2020] [Kartik] */
    },
    /* [END] [CR000022] [24-Jul-2019] [Kartik] */

    /* [START] [CR000022] [05-Aug-2019] [Kartik]
     * Upload Report
     */
    DOCUMENTS: {
      GET_DOCUMENTS: `${API.BASE_URL}/Document/GetAll`,
       /*DIpak Ahirav new changes*/
       CREATE_DOCUMENT: `${API.BASE_URL}/Document/CreateAll`,
      // CREATE_DOCUMENT: `${API.BASE_URL}/Document/CreateAll`,
      UPDATE_DOCUMENT_STATUS: `${API.BASE_URL}/Document/UpdateStatus`,
      DOWNLOAD_DOCUMENT: `${API.BASE_URL}/Document/DownloadDocument`,
      DELETE_DOCUMENT: `${API.BASE_URL}/Document/DeleteDocument`,
      GET_DOCUMENT:`${API.BASE_URL}/Document/Get`
    },
    FAMCODEMASTER:{
      GET_FAMCODEMASTER:`${API.BASE_URL}/UserMaster/GetFAMCodeList`,
      ADD_UPDATE_FAMCODE:`${API.BASE_URL}/UserMaster/Create`,
      ADD_FILE:`${API.BASE_URL}/UserMaster/CurrencyFactorExcelUpload`
    },
    LEADGENERATIONMASTER:{
      GET_LEADGENERATIONDATA:`${API.BASE_URL}/UserMaster/GetLeadGenerationDataList`
    }
    /* [END] [CR000022] [05-Aug-2019] [Kartik] */
  },
  // End

  // Region : Audit
  AUDIT: {
    GET_AUDIT_DATA: `${API.BASE_URL}/Audit/Audit`,
    SAVE: `${API.BASE_URL}/Audit/Create`,
    UPDATE: `${API.BASE_URL}/Audit/UpdateReport`,
    SUBMIT: `${API.BASE_URL}/Audit/Submit`,
    SAVE_REPORT_AND_SUBMIT: `${API.BASE_URL}/Audit/SaveAndSubmit`,
    UPDATE_REPORT_AND_SUBMIT: `${API.BASE_URL}/Audit/UpdateReportAndSubmit`,
    GET_TEAM_LEADER: `${API.BASE_URL}/Audit/GetServiceEngineersTeamLeader`,
    GET_AUDIT_LIST: `${API.BASE_URL}/Audit/GetAll`,
    GET_SUBMITTED_REPORTS: `${API.BASE_URL}/Audit/GetSubmittedReports`,
    DOWNLOAD_REPORT: `${API.BASE_URL}/Audit/DownloadReport`,
    DOWNLOAD_RECOMMENDATION_REPORTS: `${API.BASE_URL}/Audit/DownloadRecommendationReports`,
    GET_AUDIT_VIEW: `${API.BASE_URL}/Audit/GetAuditForReview`,
    GET_AUDIT_EDIT: `${API.BASE_URL}/Audit/GetAuditForEdit`,
    POST_AUDIT_REVIEW: `${API.BASE_URL}/Audit/SubmitAuditReview`,
    DRAFT_AUDIT_REVIEW: `${API.BASE_URL}/Audit/SaveDraftAuditReview`,
    SEND_AUDIT_REPORT_TO_CUSTOMER: `${API.BASE_URL}/Audit/SendAuditedReportToCustomer`,
    SEND_REPORT_BACK_TO_SERVICE_ENGG: `${API.BASE_URL}/Audit/SendReportBackToServiceEngineer`,
    LEAD_GENERATION_SUBMIT: `${API.BASE_URL}/Audit/SubmitToLeadGeneration`,
    UPDATE_AUDIT_ASSIGNTO: `${API.BASE_URL}/Audit/UpdateAuditAssignTo`
  },
  // End

  CUSTOMER: {
    GET_REQUIRED_LIST: `${API.BASE_URL}/Customer/GetCustomer`,
    CREATE_CUSTOMER: `${API.BASE_URL}/Customer/Create`,
    GET_SELECT_LIST: `${API.BASE_URL}/Customer/GetCustomerSelectList`
  },
  // End

  /* [START] [CR000022] [24-Jul-2019] [Kartik]
   * Tab - Recommendation
   */
  RECOMMENDATION: {
    GET_REQUIRED_LIST: `${API.BASE_URL}/Recommendation/GetRecommendation`,
    CREATE_RECOMMENDATION: `${API.BASE_URL}/Recommendation/Create`,
    GET_SELECT_LIST: `${API.BASE_URL}/Recommendation/GetRecommendationSelectList`
  },
  /* [END] [CR000022] [24-Jul-2019] [Kartik] */

  /* [START] [CR000022] [05-Aug-2019] [Kartik]
   * Upload Report
   */
  DOCUMENTS: {
    GET_DOCUMENTS: `${API.BASE_URL}/Document/GetAll`,
    CREATE_DOCUMENT: `${API.BASE_URL}/Document/Create`,
    UPDATE_DOCUMENT_STATUS: `${API.BASE_URL}/Document/UpdateStatus`,
    DOWNLOAD_DOCUMENT: `${API.BASE_URL}/Document/DownloadDocument`,
    DELETE_DOCUMENT: `${API.BASE_URL}/Document/DeleteDocument`,
  },
  /* [END] [CR000022] [05-Aug-2019] [Kartik] */

  // Region : Industry Type
  INDUSTRY_TYPE: {
    GET_SELECT_LIST: `${API.BASE_URL}/IndustryType/GetIndustryTypeSelectList`
  },
  // End

  USER:{
    GET_USER_FAMCODE:`${API.BASE_URL}/UserMaster/GetFAMCodeByUserId`
  }
});
