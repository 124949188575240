import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppPropertiesService } from './utility-app/services/nav-by-role.service';

import { DocumentsService } from 'src/app/superAdmin-app/documents/services/documents.service';
import { FileSaverService } from 'src/app/utility-app/services/file-saver.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as introJs from 'intro.js/intro.js';
import { State } from '@progress/kendo-data-query';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { TestimonialsService } from './superAdmin-app/testimonials-master/services/testimonial.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  // styleUrls: ['./app.component.css']
})
export class HomeComponent implements OnInit {
  title = 'AirScanWeb';
  documentList: any = [];
  testimonialList: any[];
  images: any;
  introJS = introJs();
  public totalCount: number;
  public dataCount: number = 0;
  public state: State = {
    skip: 0,
    take: 2,
    filter: {
      logic: 'and',
      filters: [{ field: 'enabled', operator: 'isEqualTo', value: true }]
    }
  };

  constructor(private _router: Router, private _appPropertiesService: AppPropertiesService,
    private _documentsService: DocumentsService,
    private _fileSaverService: FileSaverService,
    private _testimonialService: TestimonialsService,
    private sanitizer: DomSanitizer) {

    /*[START][CR000025][DIPAK AHIRAV] - Application Tour*/
    this.introJS.setOptions({
      steps: [
        {
          element: '#step1',
          intro: 'Welcome to AIRScan Platform!',
          position: 'bottom'
        },
        {
          element: '#step2',
          intro: "These are testimonials.",
          position: 'right'
        },
        {
          element: '#step3',
          intro: 'You can navigate to previous testimonial.',
          position: 'right'
        },
        {
          element: '#step4',
          intro: 'You can navigate to next testimonial.',
          position: 'right'
        },
        {
          element: '#step5',
          intro: "This is list of documents.",
          position: 'top'
        }

      ],
      showProgress: true
    });
    /*[END][CR000025][DIPAK AHIRAV] - Application Tour*/
  }

  ngOnInit() {
    // this.introJS.start();

    /*DIpak Ahirav new changes*/
    this._documentsService.getAllDocument().subscribe((data: any) => {
      
      this.documentList = data.response.records.filter(s => s.documentName !== null);

      // data.response.records.forEach(element => {
      //   // console.log("das",element);
      //   if (element.documentName !== null) {
      //     this.documentList.push(element);
      //   }
      // })
      // //  this.documentList = data.response.records;
    })

    // this._testimonialService.getAllTestimonial().subscribe((data: any) => {
    //   // console.log("total data:",data)
    //   // this.documentList = data.response.records;
    //   this.totalCount=data.response.totalCount;
    // });
    this.reloadData();
  }

  public reloadData() {
    this._testimonialService.getAllTestimonial().subscribe((data: any) => {

      this.testimonialList = data.response.records;

      this.totalCount = data.response.totalCount;

      this.images = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,');
      let i = 0;
      data.response.records.forEach(element => {
        this.images[i] = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg/png;base64,' + element.image);
        i = i + 1;
      });
    });
  }
  public applicationTour() {
    this.introJS.start();
  }
  public transform() {
    return this.images;
  }
  public downloadDocument(documentName: string) {
    this._documentsService.downloadDocument(documentName).subscribe((result: any) => {
      this._fileSaverService.fileSave(result);
    });
  }

  public nextPage(): void {
    this.totalCount = this.totalCount - 2;

    if ((this.totalCount) > 0) {

      this.dataCount = this.dataCount + 2;

      this.state.skip = this.dataCount;
      /*DIpak Ahirav new changes*/
      this._testimonialService.state2 = this.state;
      this.reloadData();

    }
    else {
      this.totalCount = this.totalCount + 2;

    }
  }
  public previousPage(): void {
    this.totalCount = this.totalCount + 2;

    if (this.dataCount > 0) {

      this.dataCount = this.dataCount - 2;

      this.state.skip = this.dataCount;
      /*DIpak Ahirav new changes*/
      this._testimonialService.state2 = this.state;
      this.reloadData();
    }
    else {

    }
  }
}
