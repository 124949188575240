import { PowerBiReportComponent } from './utility-app/components/power-bi-report/power-bi-report.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AuthenticationGuard } from 'microsoft-adal-angular6';
import { AdalAuthenticationComponent } from './app-authentication.coponent';
import { HomeComponent } from './home.component';
import { UnauthorizedComponent } from './utility-app/components/unauthorized/unauthorized.component';
import { AuthGuardService } from './app-authguard.service';
import { PageNotFoundComponent } from './utility-app/components/page-not-found/page-not-found.component';
import { InternalServerErrorComponent } from './utility-app/components/internal-server-error/internal-server-error.component';
import { ToggleRoleComponent } from './utility-app/components/toggle-role/toggle-role.component';
import { ComplaintComponent } from './utility-app/components/complaint/complaint.component';
import { TestimonialComponent } from './utility-app/components/testimonial/testimonial.component';
const routes: Routes = [
    // { path: '', redirectTo : 'auth' , pathMatch: 'full'},
    // { path: 'auth', loadChildren: './app-authentication/app-authentication.module#AppAuthenticationModule'},
    // { path: 'dashboard/master', loadChildren: './dashboard-app/dashboard-app.module#DashboardAppModule'},
    { path: '', redirectTo: 'auth', pathMatch: 'full' },
    { path: 'auth', component: AdalAuthenticationComponent, pathMatch: 'full', canActivate: [AuthenticationGuard] },
    { path: 'home', component: HomeComponent, canActivate: [AuthenticationGuard] },
    {path: 'togglerole', component: ToggleRoleComponent},
    {path:'complaint',component:ComplaintComponent},
    { path: 'engineer', loadChildren: './service-engineer-app/service-engineer-app.module#ServiceEngineerAppModule',
     canLoad: [AuthGuardService] },
    { path: 'auditor', loadChildren: './auditor-app/auditor-app.module#AuditorAppModule',
     canLoad: [AuthGuardService] },
    { path: 'admin', loadChildren: './admin-app/admin-app.module#AdminAppModule', canLoad : [AuthGuardService]},
    { path: 'superAdmin', loadChildren: './superAdmin-app/superAdmin-app.module#SuperAdminAppModule', canLoad : [AuthGuardService]},
    {path:'testimonial/:id',component:TestimonialComponent},
    { path: '401', component: UnauthorizedComponent },
    { path: '500', component: InternalServerErrorComponent },
    { path: '404', component: PageNotFoundComponent },
    { path: '**', redirectTo: '404' },
    // { path: '', redirectTo: 'auth', pathMatch: 'full' }
    // { path: 'app-auth-callback', loadChildren: './app-authentication/app-authentication.module#AppAuthenticationModule' }
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { useHash: true }),
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
