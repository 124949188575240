import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AdalAuthenticationComponent } from './app-authentication.coponent';
import { AppComponent } from './app.component';
import { HomeComponent } from './home.component';
import { UtilityAppModule } from './utility-app/utility-app.module';
import { AuthenticationGuard, MsAdalAngular6Module, MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
// import { MSActiveDirectoryConfig } from './utility-app/app-constants/ms-adal';
// import SampleJson from '../assets/config.powerbi.json';
import { AppPowerBiConfigService } from './app-powerbi-config.service';

let adalConfig: any; // will be initialized by APP_INITIALIZER
export function msAdalAngular6ConfigFactory() {
  return adalConfig; // will be invoked later when creating MsAdalAngular6Service
}

// refer to:
// https://devblogs.microsoft.com/premier-developer/angular-how-to-editable-config-files/
// for a description of the AppConfig service
export function initializeApp(appConfig: AppPowerBiConfigService) {

  const promise = appConfig.load().then(() => {
    adalConfig = {
      resource : AppPowerBiConfigService.settings.resource,
      tenant: AppPowerBiConfigService.settings.tenant,
      clientId: AppPowerBiConfigService.settings.clientId,
      redirectUri: AppPowerBiConfigService.settings.redirectUri,
      endpoints: AppPowerBiConfigService.settings.endpoints,
      navigateToLoginRequestUrl: AppPowerBiConfigService.settings.navigateToLoginRequestUrl,
      cacheLocation: AppPowerBiConfigService.settings.cacheLocation,
      extraQueryParameter: AppPowerBiConfigService.settings.extraQueryParameter,
      isAngular: AppPowerBiConfigService.settings.isAngular,
      postLogoutRedirectUri: AppPowerBiConfigService.settings.postLogoutRedirectUri
    };
  });
  return () => promise;
}

@NgModule({
  declarations: [
    AppComponent,
    AdalAuthenticationComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UtilityAppModule,
    MsAdalAngular6Module,
   // MsAdalAngular6Module.forRoot(loadConfig()),
  //  MsAdalAngular6Module.forRoot({
  //   resource : MSActiveDirectoryConfig.resource,
  //   tenant: MSActiveDirectoryConfig.tenant, // 'atlascopco.com',
  //   clientId: getClientId(),
  //   redirectUri: MSActiveDirectoryConfig.reidirectUri,
  //   endpoints: MSActiveDirectoryConfig.endpoints,
  //   navigateToLoginRequestUrl: MSActiveDirectoryConfig.navigateToLoginRequestUrl,
  //   cacheLocation: MSActiveDirectoryConfig.cacheLocation,
  //   extraQueryParameter: MSActiveDirectoryConfig.extraQueryParameter, // 'domain_hint=atlascopco.com',
  //   // logOutUri: window.location.origin,
  //   isAngular: MSActiveDirectoryConfig.isAngular,
  //   postLogoutRedirectUri: MSActiveDirectoryConfig.postLogoutRedirectUri
  // }),
    BrowserAnimationsModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppPowerBiConfigService],
      multi: true
    },
    MsAdalAngular6Service,
    {
      provide: 'adalConfig',
      useFactory: msAdalAngular6ConfigFactory,
      deps: []
    },
    AuthenticationGuard
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }
