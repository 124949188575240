import { ToggleMenusService } from './../../services/toggle-menus.service';
import { AppRoutes } from './../../app-constants/app-page-routes';
import { AppPropertiesService } from './../../services/nav-by-role.service';
import { Component, OnInit } from '@angular/core';
import { AppAuthenticationService } from '../../../app-authentication.service';
import { Router } from '@angular/router';
import { AppProperties } from '../../app-constants/app-properties.enum';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { RequestApi } from '../../app-constants/app-request-api';
import { AuthorizationModel } from '../../models/authorization-model';
import { UserRole } from '../../app-constants/app-roles.enum';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  // fields
  shouldEnableAdminMenu: boolean;
  shouldEnableSuperAdminMenu : boolean
  shouldEnableServiceEngineerMenu: boolean;
  shouldEnableAuditorMenu: boolean;
  shouldEnableToggleRoleMenu: boolean;
  loggedUserName: string;
  loggedUserRoles: string;
  appName: string;
  appVersion: string;
  // end fields

  // c'tor
  constructor(private _http: HttpClient, private _appAuthService: AppAuthenticationService,
    private _router: Router, private _toggleRoleService: ToggleMenusService) {
    this.shouldEnableAdminMenu = false;
    this.shouldEnableSuperAdminMenu = false;
    this.shouldEnableServiceEngineerMenu = false;
    this.shouldEnableAuditorMenu = false;
    this.shouldEnableToggleRoleMenu = false;
    this.loggedUserName = 'User';
    this.loggedUserRoles = '-No Roles-';
    this.appName = AppProperties.APP_NAME;
    this.appVersion = AppProperties.APP_VERSION;
  }
  // end c'tor

  // methods
  ngOnInit() {
    
    this.loggedUserName = this._appAuthService.getLoggedUserName() || this.loggedUserName;
    this._toggleRoleService.userRolesIndicator.subscribe((roles: string) => {
      this.loggedUserRoles = roles || this.loggedUserRoles;
    });
    const PARAMS = new HttpParams().set('email', this._appAuthService.getLoggedUserEmail());
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-type', 'application/json');

    this._http.get<AuthorizationModel>(RequestApi.AUTHORIZE_USER, { headers: httpHeaders, params: PARAMS }).subscribe(result => {
      
      let UserRoles = '';
      
      if (result.userRoles.length === 1) {
        this.shouldEnableToggleRoleMenu = true;
        UserRoles = result.userRoles[0].roleName;
        this._toggleRoleService.changeUserRoleMessage(UserRoles);
        if (result.userRoles[0].roleName === UserRole.SERVICE_ENGINEER.NAME) {
          this._router.navigate([AppRoutes.ENGINEER.DASHBOARD]);
          this.shouldEnableServiceEngineerMenu = true;
        } else if (result.userRoles[0].roleName === UserRole.AUDITOR.NAME) {
          this._router.navigate([AppRoutes.AUDITOR.DASHBOARD]);
          this.shouldEnableAuditorMenu = true;
        } else if (result.userRoles[0].roleName === UserRole.ADMIN.NAME) {
          this.shouldEnableAdminMenu = true;
          let self = this;
          setTimeout(function () { self._router.navigate([AppRoutes.ADMIN.DASHBOARD]); }, 0);
        } else {
          this._router.navigate([AppRoutes.UNAUTHORIZED_ACCESS]);
        }
      } else if (result.userRoles.length === 2 || result.userRoles.length === 3  || result.userRoles.length === 4 ) {
        this.shouldEnableToggleRoleMenu = true;
        

        if (result.userRoles.length === 2) {
          UserRoles = result.userRoles[0].roleName + ', ' + result.userRoles[1].roleName;
        }
        else if (result.userRoles.length === 3) {
          UserRoles = result.userRoles[0].roleName + ', ' + result.userRoles[1].roleName + ', ' + result.userRoles[2].roleName;
        }
        else if (result.userRoles.length === 4) {
          UserRoles = result.userRoles[0].roleName + ', ' + result.userRoles[1].roleName + ', ' + result.userRoles[2].roleName + ', ' + result.userRoles[3].roleName;
          if (result.userRoles[3].roleName === UserRole.SUPER_ADMIN.NAME) {
            
          // this.shouldEnableAdminMenu = true;
          // let self = this;
          // setTimeout(function () { self._router.navigate([AppRoutes.SUPER_ADMIN.DASHBOARD]); }, 0);
          }
        }

        this._toggleRoleService.changeUserRoleMessage(UserRoles);
        this._toggleRoleService.selectedRole.subscribe((role: string) => {

          switch (role) {
            case UserRole.SERVICE_ENGINEER.NAME: {
              this.shouldEnableServiceEngineerMenu = true;
              this.shouldEnableAdminMenu = false;
              this.shouldEnableAuditorMenu = false;
              this.shouldEnableSuperAdminMenu = false;
              break;
            }
            case UserRole.ADMIN.NAME: {
              this.shouldEnableServiceEngineerMenu = false;
              this.shouldEnableAdminMenu = true;
              this.shouldEnableAuditorMenu = false;
              this.shouldEnableSuperAdminMenu = false;
              break;
            }
            case UserRole.AUDITOR.NAME: {
              this.shouldEnableServiceEngineerMenu = false;
              this.shouldEnableAdminMenu = false;
              this.shouldEnableAuditorMenu = true;
              this.shouldEnableSuperAdminMenu = false;
              break;
            }
            case UserRole.SUPER_ADMIN.NAME: {
              this.shouldEnableServiceEngineerMenu = false;
              this.shouldEnableAdminMenu = false;
              this.shouldEnableAuditorMenu = false;
              this.shouldEnableSuperAdminMenu = true;
              break;
            }
            default: {
              break;
            }
          }
        });

        this._toggleRoleService.toggleMenus(result.userRoles);
        if (window.localStorage.getItem('signed') !== undefined && window.localStorage.getItem('signed') !== 'true') {
          
          this._router.navigate([AppRoutes.HOME]);//To stop redirecting to toggle role add AppRoutes.HOME insted of AppRoutes.TOGGLE_ROLE
        }
        window.localStorage.setItem('signed', 'true');

      }
       else {
        this._router.navigate([AppRoutes.UNAUTHORIZED_ACCESS]);
      }


    });

    this._toggleRoleService.userRolesLength.subscribe((len: number) => {

      if (len === 2) {
        this.shouldEnableToggleRoleMenu = true;
      } else {
        this.shouldEnableToggleRoleMenu = true;
      }

      this._toggleRoleService.selectedRole.subscribe((role: string) => {
                   
        switch (role) {   
          case UserRole.SERVICE_ENGINEER.NAME: {
            this.shouldEnableServiceEngineerMenu = true;
            this.shouldEnableAdminMenu = false;
            this.shouldEnableAuditorMenu = false;
            break;
          }
          case UserRole.ADMIN.NAME: {
            this.shouldEnableServiceEngineerMenu = false;
            this.shouldEnableAdminMenu = true;
            this.shouldEnableAuditorMenu = false;
            break;
          }
          case UserRole.AUDITOR.NAME: {
            this.shouldEnableServiceEngineerMenu = false;
            this.shouldEnableAdminMenu = false;
            this.shouldEnableAuditorMenu = true;
            break;
          }
          default: {
            break;
          }
        }
      });
    });
  }

  onLogOut(): void {
    this._appAuthService.signOutUser();
  }
  // end methods
}
