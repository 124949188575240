import { AppAuthenticationService } from 'src/app/app-authentication.service';
import { UserRoleInterface } from './../models/authorization-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserRole } from '../app-constants/app-roles.enum';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { RequestApi } from '../app-constants/app-request-api';

@Injectable({
  providedIn: 'root'
})
export class ToggleMenusService {

  private roleList = [];
  private loggedUserRoles = new BehaviorSubject([]);
  private length = new BehaviorSubject(1);
  private userRole = new BehaviorSubject('');
  private userRoleMessage = new BehaviorSubject('');
  userRoles = this.loggedUserRoles.asObservable();
  userRolesLength = this.length.asObservable();
  selectedRole = this.userRole.asObservable();
  userRolesIndicator = this.userRoleMessage.asObservable();
  constructor(private _http: HttpClient, private _appAuthService: AppAuthenticationService) { }

  toggleMenus(value: Array<UserRoleInterface>) {    
    this.roleList = value;
    this.loggedUserRoles.next(this.roleList);
    this.length.next(this.roleList.length);
  }

  changeRole(role: string) {
   this.userRole.next(role);
  }

  changeUserRoleMessage(message: string) {
    this.userRoleMessage.next(message);
  }

  public updateToggleRoles(): Observable<any> {
    const PARAMS = new HttpParams().set('email', this._appAuthService.getLoggedUserEmail());
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-type', 'application/json');

    return  this._http.get<any>(RequestApi.AUTHORIZE_USER, {headers: httpHeaders, params: PARAMS });

  }
}
