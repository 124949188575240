export const AppRegExp = Object.freeze({
  ALPHABATES: {
    RegEx: /^(?!\s)[a-zA-Z]*$/ as RegExp,
    ErrorMessage: 'Please enter only alphabates'
  },
  ALPHA_NUMERIC_WITH_WHITE_SPACE: {
    RegEx: /^(?!\s)[a-zA-Z0-9\s]*$/ as RegExp,
    ErrorMessage: 'Please enter only alpha-numeric with space'
  },
  NUMERIC: /^[0-9]*$/ as RegExp,
  ALPHA_NUMERIC: /^[a-zA-Z0-9]*$/ as RegExp,
  APHABATES_WITH_WHITE_SPACE: {
    RegEx: /^(?!\s)[a-zA-Z\s]*$/ as RegExp,
    ErrorMessage: 'Please enter only alphabates with space'
  },
  NUMERIC_WITH_WHITE_SPACE: /^[0-9\s]*$/ as RegExp,

  EMAIL: {
    RegEx: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/ as RegExp,
    ErrorMessage: 'Please enter valid email'
  }
});
