// export enum UserRole {
//     ADMIN = 'Admin',
//     SERVICE_ENGINEER = 'Service Engineer',
//     AUDITOR = 'Auditor'
// }

export const UserRole = Object.freeze({
  SERVICE_ENGINEER: {
    NAME: 'Service Engineer',
    VALUE: 1
  },
  ADMIN: {
    NAME: 'Admin',
    VALUE: 2
  },
  AUDITOR: {
    NAME: 'Auditor',
    VALUE: 3
  },
  SUPER_ADMIN:{
    NAME: 'Super Admin',
    VALUE: 4
  }
});


