/* [START] [CR000022] [05-Aug-2019] [Kartik]
 * Upload Report
 * a. Add new master "Upload Report" (same as Custome Master / User Master).
	  Only for Admin
 * b. Upload Report Grid : Functionalities
      i) Active / Inactive
      ii) Delete
      iii) Search
 * c. Validations
      i) Check for same name document.
 * d. Show uploaded reports in right side collapsible panel for download.
	  Only for Users
 */
import { Injectable, DebugElement } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { State } from '@progress/kendo-data-query';

import { Observable } from 'rxjs';

import { RequestApi } from 'src/app/utility-app/app-constants/app-request-api';
import { APIResult } from 'src/app/utility-app/models/api-result.interface';
import { LoaderMessageService } from 'src/app/utility-app/services/loader-message.service';

import DocumentsModel from '../models/documents.model';

@Injectable({
  providedIn: 'root'
})

export class DocumentsService {
  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [{ field: 'documentName', operator: 'contains', value: '' }]
    }
  };

  private data: any[] = [];

  constructor(private _http: HttpClient, private _loaderMessageService: LoaderMessageService) { }

  public getDocumentForUpdate(documentId: number): Observable<DocumentsModel> {
    this._loaderMessageService.setLoaderMessage('Fetching document details');
    const PARAMS = new HttpParams().set('documentId', documentId.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this._http.get<DocumentsModel>(RequestApi.MASTERS.DOCUMENTS.GET_DOCUMENTS, { params: PARAMS, headers });
  }

  public createDocument(documentFile: File): Observable<APIResult> {
    this._loaderMessageService.setLoaderMessage('Uploading document details');
    const formData = new FormData();
    formData.append('documentFile', documentFile['rawFile']);
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this._http.post<any>(RequestApi.MASTERS.DOCUMENTS.CREATE_DOCUMENT, formData, { headers });
  }

  public getDocumentList(): Observable<any> {
    this._loaderMessageService.setLoaderMessage('Fetching document records');
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this._http.post<any>(RequestApi.MASTERS.DOCUMENTS.GET_DOCUMENTS, this.state, { headers });
  }

  public getAllDocument(): Observable<any> {
    this._loaderMessageService.setLoaderMessage('Fetching document records');
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const state: State = {
      skip: 0,
      take: 2147483647,
      filter: {
        logic: 'and',
        filters: [{ field: 'documentName', operator: 'contains', value: '' }]
      }
    };
    return this._http.post<any>(RequestApi.MASTERS.DOCUMENTS.GET_DOCUMENTS, state, { headers });
  }

  public updateDocumentStatus(documentName: string): Observable<APIResult> {
    this._loaderMessageService.setLoaderMessage('Updating document status');
    const HEADERS = new HttpHeaders();
    HEADERS.append('Content-Type', 'application/json');
    const PARAMS = new HttpParams().set('documentName', documentName);
    return this._http.get<any>(RequestApi.MASTERS.DOCUMENTS.UPDATE_DOCUMENT_STATUS, { params: PARAMS, headers: HEADERS });
  }

  public deleteDocument(documentName: string): Observable<APIResult> {
    this._loaderMessageService.setLoaderMessage('Deleting document details');
    const PARAMS = new HttpParams().set('documentName', documentName);
    return this._http.delete<APIResult>(RequestApi.MASTERS.DOCUMENTS.DELETE_DOCUMENT, { params: PARAMS });
  }

  public downloadDocument(documentName: string): Observable<any> {
    const HEADERS = new HttpHeaders();
    HEADERS.append('Content-Type', 'application/json');
    const PARAMS = new HttpParams().set('documentName', documentName);
    return this._http.get<any>(RequestApi.MASTERS.DOCUMENTS.DOWNLOAD_DOCUMENT, {
      params: PARAMS, headers: HEADERS,
      responseType: 'blob' as 'json', observe: 'response'
    });
  }

}
/* [END] [CR000022] [05-Aug-2019] [Kartik] */
