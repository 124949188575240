import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseVariable } from 'src/environments/environment';

@Component({
  selector: 'app-power-bi-report',
  templateUrl: './power-bi-report.component.html',
  styleUrls: ['./power-bi-report.component.scss']
})
export class PowerBiReportComponent implements OnInit {
 
  reporturl : any;
  constructor(public sanitizer :DomSanitizer) {
    const url  = BaseVariable.REPORT_URL; 
    this.reporturl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    getURL(){
      return  this.reporturl = BaseVariable.REPORT_URL; 
    }
  ngOnInit() {
  }
}
