import { AppPowerBiConfigService } from './app-powerbi-config.service';
import { Injectable, OnInit } from '@angular/core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppAuthenticationService implements OnInit {
    isUserAuthenticated: BehaviorSubject<boolean>;
    protected clientId = AppPowerBiConfigService.settings.clientId;
    protected resource: string;
    constructor(private _msAdalService: MsAdalAngular6Service, private _router: Router) {
        this.isUserAuthenticated = new BehaviorSubject<boolean>(false);
        this.resource = AppPowerBiConfigService.settings.resource;
    }

    ngOnInit() {
        
    }

    public acquireToken(): Observable<string> {
        return this._msAdalService.acquireToken('https://login.microsoftonline.com');
    }

    public acquirePowerBIAccessToken(): Observable<string> {
        return this._msAdalService.acquireToken(this.resource);
    }

    public renewToken(): void {
        return this._msAdalService.RenewToken('https://login.microsoftonline.com');
    }

    public getToken(): string {
        let token = sessionStorage.getItem(`adal.access.token.key${this.clientId}`);

        if (token === undefined) {
            token = '';
        }
        return token;
    }

    public getPowerBiToken(): string {
        let token = sessionStorage.getItem('adal.access.token.key' + this.resource);

        if (token === undefined) {
            token = '';
        }
        return token;
    }

    public handleCallback(): void {
        this._msAdalService.handleCallback();
    }

    public signOutUser(): void {
        this._msAdalService.logout();
        this._msAdalService.handleCallback();
    }

    public getLoggedUserEmail(): string {
        return this._msAdalService.LoggedInUserEmail;
    }

    public getLoggedUserName(): string {
        return this._msAdalService.LoggedInUserName;
    }

    public isAuthenticated(): void {
        // Check whether the current time is past the
        // access token's expiry time
        let isAuthenticated = false;
        // 'adal.expiration.keya7890241-e2bd-4c22-98bb-444aae199358'
        // let key = `adal.expiration.key${MSActiveDirectoryConfig.clientId}`;
        let key = `adal.expiration.key${this.clientId}`;

        const expiresAt = JSON.parse(sessionStorage.getItem(key) || '{}');
        if (isNaN(expiresAt)) {
            isAuthenticated = false;
        } else {
            const convertedDt = new Date(expiresAt * 1000);
            if ((new Date().getTime() < convertedDt.getTime())) {
                isAuthenticated = true;
            } else {
                isAuthenticated = false;
                this._msAdalService.logout();
                // this.renewToken();
            }
        }

        this.isUserAuthenticated.next(isAuthenticated);
    }

    private isUserLoggedIn(): Observable<boolean> {
        this.isAuthenticated();
        return this.isUserAuthenticated.asObservable();
    }

    public isUserValid(): boolean {
        let isValid = false;
        this.isUserLoggedIn().subscribe((value: boolean) => {
            isValid = value;
        });
        return isValid;
    }

    public getUserInfo(): any {
        return this._msAdalService.userInfo;
    }

}
