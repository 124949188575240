export enum AppProperties {
  IS_SUPER_ADMIN = 'IS_SUPER_ADMIN',
  IS_ADMIN = 'IS_ADMIN',
  IS_ENGINEER = 'IS_ENGINEER',
  IS_AUDITOR = 'IS_AUDITOR',
  USER_NAME = 'USER_NAME',
  USER_EMAIL = 'USER_EMAIL',
  APP_NAME = 'AIRScan',
  SHOUD_OPEN_CUSTOMER_ADD_MODAL = 'SHOUD_OPEN_CUSTOMER_ADD_MODAL',
  SHOUD_OPEN_RECOMMENDATION_ADD_MODAL = 'SHOUD_OPEN_RECOMMENDATION_ADD_MODAL',
  APP_VERSION = 'v1.0.0'
}
