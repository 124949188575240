import { AppRoutes } from './../../app-constants/app-page-routes';
import { UserRole } from './../../app-constants/app-roles.enum';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ToggleMenusService } from '../../services/toggle-menus.service';
import { UserRoleInterface, AuthorizationModel } from '../../models/authorization-model';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { RequestApi } from '../../app-constants/app-request-api';
import { AppAuthenticationService } from 'src/app/app-authentication.service';
import { CustomerRequiredDataService } from 'src/app/service-engineer-app/customer/services/customer-required-data.service';
import { NotificationService } from '../../services/notification.service';
import { NotificationType } from '../../app-constants/app-notifications.enum';
import * as introJs from 'intro.js/intro.js';

@Component({
  selector: 'app-toggle-role',
  templateUrl: './toggle-role.component.html',
  styleUrls: ['./toggle-role.component.scss']
})
export class ToggleRoleComponent implements OnInit {
  public userRoles: Array<UserRoleInterface>;
  public selectedUserRoles: Array<UserRoleInterface>;
  public famecode: any;
  public defaultItem: string;
  public checkedFamcode: string;
  public selectedFamcode: string;
  public alreadySelected: boolean = false;
  introJS = introJs();
  constructor(private _router: Router, private _toggleRoleService: ToggleMenusService,
    private _appAuthService: AppAuthenticationService, private _http: HttpClient,
    private _mainService: CustomerRequiredDataService, private _notificationService: NotificationService) {
    this.userRoles = [];
    this.selectedUserRoles = [];

    /*[START][CR000025][DIPAK AHIRAV] - Application Tour*/
    this.introJS.setOptions({
      steps: [
        {
          element: '#step1',
          intro: 'Select a FAM Code',
          position: 'left'
        }
      ],
      showProgress: true
    });
    /*[END][CR000025][DIPAK AHIRAV] - Application Tour*/
  }

  ngOnInit() {
    //  [START] [CR000025] [13-FEB-2020] [DIPAK AHIRAV] [FAM CODE OF USER LOGGED IN]
    const PARAMS = new HttpParams().set('email', this._appAuthService.getLoggedUserEmail());
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-type', 'application/json');

    this._http.get<AuthorizationModel>(RequestApi.AUTHORIZE_USER, { headers: httpHeaders, params: PARAMS }).subscribe(result => {

      // this.userRoles = result.userRoles;
      this._mainService.getUserFamCodes(result.userId).subscribe((result: any) => {

        this.famecode = result.response;
        if (this.famecode.length == 1) {
          this.selectedFamcode = this.famecode[0];
          localStorage.setItem('toggleFamCode',this.selectedFamcode);
          localStorage.setItem('selectedAuditFam',this.selectedFamcode);
        }
        // this.selectedFamcode=this.famecode[0];
        // localStorage.setItem('toggleFamCode',this.selectedFamcode);
      });

    });

    if (!this.alreadySelected) {
      // // localStorage.setItem('toggleFamCode',this.selectedFamcode);
      this.selectedFamcode = localStorage.getItem('toggleFamCode');

    }

    //  [END] [CR000025] [13-FEB-2020] [DIPAK AHIRAV] [FAM CODE OF USER LOGGED IN]
    this._toggleRoleService.userRoles.subscribe((result: Array<UserRoleInterface>) => {
      // const mappedEvents = result.map(e => {
      //   if (e.roleName === UserRole.SERVICE_ENGINEER) {
      //      e.routerLink = AppRoutes.ENGINEER.DASHBOARD;
      //   } else if ( e.roleName === UserRole.ADMIN) {
      //     e.routerLink = AppRoutes.ADMIN.DASHBOARD;
      //   } else if ( e.roleName === UserRole.AUDITOR) {
      //     e.routerLink = AppRoutes.AUDITOR.DASHBOARD;
      //   }
      //   return e;
      // });
      if (result.length === 4) {
        result.forEach(element => {
          if (element.roleId === 4) {
            this.selectedUserRoles[0] = element;
          }
          else if (element.roleId === 2) {
            this.selectedUserRoles[1] = element;
          } else if (element.roleId === 3) {
            this.selectedUserRoles[2] = element;
          } else if (element.roleId === 1) {
            this.selectedUserRoles[3] = element;
          }
        });
        
        this.userRoles = this.selectedUserRoles;
      }
      else {        
        this.userRoles = result;
      }
    });



  }
  /*[START][CR000025][DIPAK AHIRAV] - Application Tour*/
  public applicationTour() {
    this.introJS.start();
  }
  /*[END][CR000025][DIPAK AHIRAV] - Application Tour*/
  public handleCategoryChange(event) {

    this.checkedFamcode = event.split(' ').slice(0, 1).join(' ');
    localStorage.setItem('toggleFamCodeEvent', event);
    localStorage.setItem('toggleFamCode', this.checkedFamcode);
    localStorage.setItem('selectedAuditFam',this.checkedFamcode);
    this.alreadySelected = true;
  }

  public onRoleSelect(role: string): void {

    
    if (this.selectedFamcode !== "Select" && this.selectedFamcode !== undefined && this.selectedFamcode !== null && this.selectedFamcode !== '') {
      switch (role) {
        case UserRole.SERVICE_ENGINEER.NAME: {
          this._toggleRoleService.changeRole(UserRole.SERVICE_ENGINEER.NAME);
          this._router.navigate([AppRoutes.ENGINEER.DASHBOARD]);
          break;
        }
        case UserRole.ADMIN.NAME: {
          this._toggleRoleService.changeRole(UserRole.ADMIN.NAME);
          this._router.navigate([AppRoutes.ADMIN.DASHBOARD]);
          break;
        }
        case UserRole.AUDITOR.NAME: {
          this._toggleRoleService.changeRole(UserRole.AUDITOR.NAME);
          this._router.navigate([AppRoutes.AUDITOR.DASHBOARD]);
          break;
        }
        case UserRole.SUPER_ADMIN.NAME: {
          this._toggleRoleService.changeRole(UserRole.SUPER_ADMIN.NAME);
          this._router.navigate([AppRoutes.SUPER_ADMIN.DASHBOARD]);
          break;
        }
        default: {
          this._router.navigate([AppRoutes.UNAUTHORIZED_ACCESS]);
          break;
        }
      }
    }
    else {
      this._notificationService.showNotification(NotificationType.ERROR,
        'Please Select FAM code', '');
      return;
    }
  }

}
