import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderMessageService {
  loaderMessage: string;
  private loaderSharedMessage = new BehaviorSubject('');
  message = this.loaderSharedMessage.asObservable();

  constructor() { }

  public setLoaderMessage(message: any) {
    this.loaderMessage = message;
    this.loaderSharedMessage.next(this.loaderMessage);
  }
}
