import { CustomerMasterModel } from './../models/customer-master.model';
import { CustomerAdd } from './../models/customer-add.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { RequestApi } from '../../../utility-app/app-constants/app-request-api';
import { APPDATA } from '../../../utility-app/app-constants/app.data';
import { LoaderMessageService } from 'src/app/utility-app/services/loader-message.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerRequiredDataService {

  constructor(private _http: HttpClient, private _loaderMessageService: LoaderMessageService) { }

  public getCustomerRequiredData(): Observable<Array<object>> {
    this._loaderMessageService.setLoaderMessage('Fetching required data');
    return this._http.get<Array<object>>(RequestApi.INDUSTRY_TYPE.GET_SELECT_LIST);
  }

  public getCountryList(): Array<string> {
   return APPDATA.COUNTRIES;
  }

  // <!--[START] [DIPAK AHIRAV][10-Feb-2020] GET FAM CODE THAT USER BELONGS TO-->
  public getUserFamCodes(userId:number){
    
    const PARAMS = new HttpParams().set('userId', userId.toString());
    const HEADERS = new HttpHeaders().set('content-type', 'application/json');
    return this._http.get<Array<object>>(RequestApi.USER.GET_USER_FAMCODE,{ params: PARAMS, headers: HEADERS });
  }
  // <!--[END] [DIPAK AHIRAV][10-Feb-2020] -->


  public postNewCustomer(customer: CustomerMasterModel): Observable<any> {
    
    this._loaderMessageService.setLoaderMessage('Saving Customer record');

    // const params = new HttpParams().set('SupplierName', supplier);
    //  params.set('Location', location);
   // return  this._http.get<any>(environment.api_url + 'supplier/SaveSequenceNumber/?SupplierName=' + supplier + '&Location=' + location);
   return  this._http.post<CustomerMasterModel>(RequestApi.CUSTOMER.CREATE_CUSTOMER, customer);

  }
}
