const APP = Object.freeze({
  BASE_HREF: '/',
  ADMIN: 'admin/',
  ENGINEER: 'engineer/',
  AUDITOR: 'auditor/',
  SUPER_ADMIN:'superAdmin/'
});

export const AppRoutes = Object.freeze({
  // Region : MISC
  BASE: APP.BASE_HREF,
  PAGE_NOT_FOUND: `${APP.BASE_HREF}404`,
  UNAUTHORIZED_ACCESS: `${APP.BASE_HREF}401`,
  INTERNAL_SERVER_ERROR: `${APP.BASE_HREF}500`,
  TOGGLE_ROLE: `${APP.BASE_HREF}togglerole`,
  // End


  // Region : Home
  HOME: `${APP.BASE_HREF}home`,
  // End

  SUPER_ADMIN:{
    HOME: `${APP.BASE_HREF}${APP.SUPER_ADMIN}`,
    DASHBOARD: `${APP.BASE_HREF}${APP.SUPER_ADMIN}dashboard`,
    MASTERS:{
      USER:{
        UPDATE: `${APP.BASE_HREF}${APP.SUPER_ADMIN}master/user/update/`,
        LIST:`${APP.BASE_HREF}${APP.SUPER_ADMIN}master/user`,
      },
      CUSTOMER:{
        UPDATE:`${APP.BASE_HREF}${APP.SUPER_ADMIN}master/customer/update/`,
        LIST:`${APP.BASE_HREF}${APP.SUPER_ADMIN}master/customer`,
      },
        /* [START] [CR000025] [11-march-2020] [Dipak Ahirav]
       * Add - Document
       */
      DOCUMENT:{
        ADD:`${APP.BASE_HREF}${APP.SUPER_ADMIN}master/document/add`,
        LIST:`${APP.BASE_HREF}${APP.SUPER_ADMIN}master/documents`,
        UPDATE:`${APP.BASE_HREF}${APP.SUPER_ADMIN}master/documents/update/`
      },
      TESTIMONIAL:{
        ADD:`${APP.BASE_HREF}${APP.SUPER_ADMIN}master/testimonial/add`,
        LIST:`${APP.BASE_HREF}${APP.SUPER_ADMIN}master/testimonials`,
        UPDATE:`${APP.BASE_HREF}${APP.SUPER_ADMIN}master/testimonial/update/`
      },
      COMPLAINT:{
        VIEW:`${APP.BASE_HREF}${APP.SUPER_ADMIN}master/complaint/view/`,
        LIST:`${APP.BASE_HREF}${APP.SUPER_ADMIN}master/complaintList`
      }

       /* [END] [CR000025] [11-march2020] [Dipak Ahirav] */
    }
  },

  // Region : Admin
  ADMIN: {
    HOME: `${APP.BASE_HREF}${APP.ADMIN}`,
    DASHBOARD: `${APP.BASE_HREF}${APP.ADMIN}dashboard`,
    MASTERS: {
      USER: {
        LIST: `${APP.BASE_HREF}${APP.ADMIN}master/user`,
        CREATE: `${APP.BASE_HREF}${APP.ADMIN}master/user/create`,
        UPDATE: `${APP.BASE_HREF}${APP.ADMIN}master/user/update/`,
        VIEW:`${APP.BASE_HREF}${APP.ADMIN}master/user/view/`
      },
      CUSTOMER: {
        LIST: `${APP.BASE_HREF}${APP.ADMIN}master/customer`,
        CREATE: `${APP.BASE_HREF}${APP.ADMIN}master/customer/create`,
        UPDATE: `${APP.BASE_HREF}${APP.ADMIN}master/customer/update/`,
        VIEW: `${APP.BASE_HREF}${APP.ADMIN}master/customer/view/`
      },
      /* [START] [CR000022] [24-Jul-2019] [Kartik]
       * Master - Add "Audit Recommendations"
       */
      RECOMMENDATION: {
        LIST: `${APP.BASE_HREF}${APP.ADMIN}master/recommendation`,
        CREATE: `${APP.BASE_HREF}${APP.ADMIN}master/recommendation/create`,
        UPDATE: `${APP.BASE_HREF}${APP.ADMIN}master/recommendation/update/`
      }
      /* [END] [CR000022] [24-Jul-2019] [Kartik] */
      
    }
  },
  // End

  // Region : Admin
  AUDITOR: {
    HOME: `${APP.BASE_HREF}/${APP.AUDITOR}`,
    DASHBOARD: `${APP.BASE_HREF}/${APP.AUDITOR}dashboard`,
    REPORT_REVIEW: `${APP.BASE_HREF}/${APP.AUDITOR}reportreview/`
  },
  // End

  // Region : Service Engineer
  ENGINEER: {
    HOME: `${APP.BASE_HREF}${APP.ENGINEER}`,
    DASHBOARD: `${APP.BASE_HREF}${APP.ENGINEER}dashboard`,
    AUDIT: `${APP.BASE_HREF}${APP.ENGINEER}audit`,
    REPORT_VIEW: `${APP.BASE_HREF}/${APP.ENGINEER}reportview/`,
    REPORT_EDIT: `${APP.BASE_HREF}/${APP.ENGINEER}reportedit/`
  }
  // End
});
