import { Injectable } from '@angular/core';
import saveAs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FileSaverService {
  constructor() {}

  public fileSave(response: any): void {
    const file = new Blob([response.body], { type: response.body.type });
    const contentDispositionHeader: string = response.headers.get(
      'Content-Disposition'
    );
    const parts: string[] = contentDispositionHeader.split(';');
    const filename = parts[1]
      .split('=')[1]
      .replace(/"/g, '')
      .trim();

    saveAs(file, filename);
  }
}
