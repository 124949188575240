import { SelectModel } from '../../../superAdmin-app/models/common.model';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AppRegExp } from 'src/app/utility-app/app-constants/app-regexp.enum';
import CustomerMasterModel from '../../../superAdmin-app/customer-master/models/customer-master.model';
import { NotificationService } from 'src/app/utility-app/services/notification.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CustomerMasterService } from '../../../superAdmin-app/customer-master/services/customer-master.service';
import { NotificationType } from 'src/app/utility-app/app-constants/app-notifications.enum';
import { APIResult } from 'src/app/utility-app/models/api-result.interface';
import { AppRoutes } from 'src/app/utility-app/app-constants/app-page-routes';
import * as introJs from 'intro.js/intro.js';
import { DocumentsService } from 'src/app/superAdmin-app/documents/services/documents.service';
import DocumentsModel from 'src/app/superAdmin-app/documents/models/documents.model';
import { DomSanitizer } from '@angular/platform-browser';
import { TestimonialModel } from '../../models/testimonial-model';
import { TestimonialsService } from 'src/app/superAdmin-app/testimonials-master/services/testimonial.service';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {
 
  introJS = introJs();
  documents:TestimonialModel;
  authorImage:any;
  author:string;
  testimonial:string;
  constructor(private _formBuilder: FormBuilder, private _customerMasterService: CustomerMasterService,
    private _notificationService: NotificationService, private _router: Router,
    private _route: ActivatedRoute,private _testimonialService:TestimonialsService,
    private _sanitizer:DomSanitizer) { 
       /*[START][CR000025][20-march-2020][DIPAK AHIRAV] - Application Tour*/
       this.introJS.setOptions({
        steps: [
        {
           element: '#step1',
           intro: "Author image.",
           position: 'bottom'
        },
        {
           element: '#step2',
           intro: "Author name.",
           position: 'right'
        },
        {
           element: '#step3',
           intro: "Testimonial content.",
           position: 'top'
        }
        
     ],
     showProgress: true
    });
    /*[END][CR000025][20-march-2020][DIPAK AHIRAV] - Application Tour*/
    

    }

  // convenience getter for easy access to form fields
  // get f() { return this.complaintMasterForm.controls; }



  ngOnInit() {
    // this.countrySelectList = this._customerMasterService.getCountryList();
    // this.industryTypeSelectList =  [];
    // this.complaintMasterForm = this.createComplaintMasterForm();



    this._route.params.subscribe((params:Params)=>{
      if(params['id'] !== null && params['id'] !== undefined){
        this._testimonialService.getDocumentForUpdate(params['id']).subscribe((result:any)=>{
          this.documents=result.response;
          this.author=result.response.author;
          this.testimonial=result.response.testimonial;

          this.authorImage=this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg/png;base64,'+result.response.image)

        })
      }
    })

    this._customerMasterService.getIndustryTypeList().subscribe((result: Array<SelectModel>) => {
     if (result && Array.isArray(result)) {
        //  this.industryTypeSelectList = result;
     }
    });

  }

  
  /*[START][CR000025][DIPAK AHIRAV][20-March-2020] - Application Tour*/
  public applicationTour(){
      
    this.introJS.start();
  
  }
/*[END][CR000025][DIPAK AHIRAV][20-March-2020] - Application Tour*/
  // private createComplaintMasterForm(): FormGroup {
  //   return this._formBuilder.group({
  //     customerId: new FormControl(0),

  //     ComplainantName: new FormControl(null, [Validators.required, Validators.pattern(this.RegExConstants.APHABATES_WITH_WHITE_SPACE.RegEx),
  //     Validators.maxLength(30)]),

  //     complaintRegarding: new FormControl(null, [Validators.required, Validators.maxLength(150),
  //     Validators.pattern(this.RegExConstants.ALPHA_NUMERIC_WITH_WHITE_SPACE.RegEx)]),
  //     mobileNo: new FormControl(null, Validators.compose([Validators.required, Validators.pattern(this.RegExConstants.NUMERIC)])),
  //     email: new FormControl(null, [Validators.required, Validators.pattern(this.RegExConstants.EMAIL.RegEx), Validators.maxLength(50)]),
  //     image: new FormControl(null)
  // });

  // }
public onBackToHome(){
    this._router.navigate([AppRoutes.HOME]);
}

 
}
