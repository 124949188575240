import { APIResult } from 'src/app/utility-app/models/api-result.interface';
import { SelectModel } from './../../../service-engineer-app/models/audit-model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoaderMessageService } from 'src/app/utility-app/services/loader-message.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { RequestApi } from 'src/app/utility-app/app-constants/app-request-api';
import { map, tap } from 'rxjs/operators';
import { UserMasterSearchInterface, UserMasterModel } from '../models/user-master.model';
import { State } from '@progress/kendo-data-query';
import { AuthorizationModel } from 'src/app/utility-app/models/authorization-model';
import { AppAuthenticationService } from 'src/app/app-authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserMasterService {
  public searchModel: UserMasterSearchInterface;
  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [{ field: 'userName', operator: 'contains', value: '' }]
    }
  };
  private data: any[] = [];

  constructor(private _http: HttpClient, private _loaderMessageService: LoaderMessageService,
    private _appAuthService: AppAuthenticationService) {  }

  public getUserInfoFromActiveDirectory(userName: string): Observable<any> {
    this._loaderMessageService.setLoaderMessage('Fetching report information');
    const PARAMS = new HttpParams().set('userName', userName);
    const HEADERS = new HttpHeaders();
    HEADERS.append('Content-Type', 'application/json');
    return this._http.get<any>(RequestApi.MASTERS.USER.GET_USER_INFO_FROM_AD, {params: PARAMS , headers: HEADERS});
  }

  public getAppRoles(): Observable<APIResult> {
    this._loaderMessageService.setLoaderMessage('Fetching required data');
    const HEADERS = new HttpHeaders();
    HEADERS.append('Content-Type', 'application/json');
    return this._http.get<APIResult>(RequestApi.MASTERS.USER.GET_ROLES, { headers: HEADERS});
  }

  public getTeamLeaderSelectList(): Observable<APIResult> {
    this._loaderMessageService.setLoaderMessage('Fetching required data');
    const HEADERS = new HttpHeaders();
    HEADERS.append('Content-Type', 'application/json');
    return this._http.get<APIResult>(RequestApi.MASTERS.USER.GET_TEAM_LEADER_LIST, { headers: HEADERS});
  }


  public getFAMCodeList(): Observable<APIResult> {
    this._loaderMessageService.setLoaderMessage('Fetching required data');
    const HEADERS = new HttpHeaders();
    HEADERS.append('Content-Type', 'application/json');
    return this._http.get<APIResult>(RequestApi.MASTERS.USER.GET_FAM_CODE_LIST, { headers: HEADERS});
  }
  // <!--[START] [DIPAK AHIRAV][12-Feb-2020] -->
  public getWithKeyFAMCodeList(): Observable<APIResult> {
    this._loaderMessageService.setLoaderMessage('Fetching required data');
    const HEADERS = new HttpHeaders();
    HEADERS.append('Content-Type', 'application/json');
    return this._http.get<APIResult>(RequestApi.MASTERS.USER.GET_KEY_FAM_CODE_LIST, { headers: HEADERS});
  }
  // <!--[END] [DIPAK AHIRAV][12-Feb-2020] -->

  public getUserForUpdate(userId: number): Observable<UserMasterModel> {
    this._loaderMessageService.setLoaderMessage('Fetching user details');
    const PARAMS = new HttpParams().set('userId', userId.toString());
    const HEADERS = new HttpHeaders().set('content-type', 'application/json');
   return  this._http.get<UserMasterModel>(RequestApi.MASTERS.USER.GET_USER, { params: PARAMS, headers: HEADERS });
  }

  public createOrUpdateUser(user: UserMasterModel): Observable<APIResult> {
    this._loaderMessageService.setLoaderMessage('Saving user details');
   return  this._http.post<any>(RequestApi.MASTERS.USER.UPSERT, user);

  }

  public getUserList(): Observable<any> {
    this._loaderMessageService.setLoaderMessage('Fetching user records');
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this._http.post<any>(RequestApi.MASTERS.USER.GET_ALL_USERS, this.state, { headers });
  }
  /*[START][CR000025][Dipak Ahirav][16-April-2020]- export excel All data*/
  public getAllUserList(): Observable<any> {
    this._loaderMessageService.setLoaderMessage('Fetching user records');
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const state: State = {
      skip: 0,
      take:99999999
    };
    return this._http.post<any>(RequestApi.MASTERS.USER.GET_ALL_USERS, state, { headers });
  }
/*[END][CR000025][Dipak Ahirav][16-April-2020]- export excel All data*/
}
